.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;
   
      &-items {
          position: absolute;
          border-bottom: none;
          border-top: none;
          z-index: 99;
          /*position the autocomplete items to be the same width as the container:*/
          top: 100%;
          left: 0;
          right: 0;
          margin: 0 13px;
          max-height: 400px;
          overflow-y: scroll;
          .single{
            line-height: 19px;
            .name{
              font-size: 1em;
            }
          }
          div {
            padding: 10px;
            cursor: pointer;
            background-color: #fff;
            border-bottom: 1px solid #d4d4d4;
            &:hover {
                /*when hovering an item:*/
                background-color: #e9e9e9;
              }
          }
        }
        &-active{
          /*when navigating through the items using the arrow keys:*/
          background-color: DodgerBlue !important;
          color: #ffffff;
        }  
}