.btn{
    &-light-blue{
        background-color: $light-blue;
        opacity: 0.8;
        border-color: $light-blue;
        color: $white;
        &:hover{
        background-color: $light-blue;
        opacity: 1;
        color: $white;
        }
    }
    &-purple-blue{
        background-color: $purple;
        border-color: $purple;
        color: $white;
        &:hover{
        background-color: $purple;
        opacity: 1;
        color: $white;
        }
    }
    &-filter{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @media screen and (max-width: 650px) {
            width: 100%;
            border-radius: 5px;
        }
    }
}

