// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap/scss/bootstrap';
@import "../css/bootstrap-datepicker3.min.css";
@import "@fortawesome/fontawesome-free/css/all.css";
@import '~bootstrap-select/sass/bootstrap-select.scss';
@import 'html.scss';
@import 'autocomplete.scss';
@import 'buttons.scss';
@import 'header.scss';
@import 'pages/pages.scss';
