.submitter_home{
    &_action_button{
        .action_btn{
            padding: 10px 20px;

            .submit_event{
                background-color: #5b3392;
                transition: all 0.2s;
                &:hover{
                    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                }
            }
            .submit_event_overview{
                background-color: #0084a8;
                transition: all 0.2s;
                &:hover{
                    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                }
            }
            .submit_event_attention{
                background-color: #f42c28;
                transition: all 0.2s;
                &:hover{
                    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
                }
            }
            &_wrap{
                .icon{
                    .icon-font{
                        font-size: 2.8em;
                        color: $white;
                    }
                }
                padding: 20px 0;
            }
        }
    }
    &_flow{
        .flow_image{
            img{
                object-fit: contain;
                width: 100%;
                height: 400px;
                mix-blend-mode: multiply;
                object-position: left;
                @media (max-width: 600px) {
                    height: 200px;
                }
            }
        }
    }
}