// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$light-blue: #0084a8;
$purple: #5b3392;
$light-grey: #6e6e72;
$bg-light-grey: #f5f5f5;
$black2: #212529;