.header{
    //overflow: hidden;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%;
    // height: 100px;
    display: flex;
    background-color: $bg-light-grey;
    z-index: 1000;
    //padding: 18px 18px;

    .navbar{
        &-brand{
            .brand-logo{
                width: 200px;
                object-fit: contain;
                @media screen and (max-width: 320px) {
                    width: 140px;
                }
            }
        }
    }
}
.navbar-nav .nav-link{
    margin: 0 10px;
    padding: 8px 15px;
}
.navbar-nav .nav-link.active{
    color: $white !important;
    background-color: $light-blue ;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: $light-blue !important;
}
