body {
    @media screen and (min-width: 1401px) {
        font-size: 16px;
    }
    @media screen and (max-width: 1400px) {
        font-size: 14px;
    }
    // @media screen and (max-width: 600px) {
    //     font-size: 14px;
    // }
}

.main-content {
    margin-top: 100px;
    padding: 0 75px;

    @media screen and (max-width: 650px) {
        padding: 0 10px;
    }
}

h1, h2, h3, h4 {
    color: $light-blue;
    font-weight: 600;
}

label {
    color: $light-blue;
}

.form-control, .form-select, .input-group-text {
    border-color: $light-blue;
}

.bg-light-grey {
    background-color: $bg-light-grey;
}

.text-purple {
    color: $purple;
}

.text-light-blue {
    color: $light-blue;
}

.text-black2 {
    color: $black2;
}

.disabled-div {
    background-color: #e9ecef;
    opacity: 0.7;
    pointer-events: none;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.select2-dropdown {
    z-index: 1000000;
}

.form-check-input {
    border-color: $light-blue;
    width: 1.2em;
    height: 1.2em;
}

.form-label {
    &.asterisk::after {
        content: '*';
        margin-left: 4px;
        color: red;
    }
}

.bg-light-blue {
    background-color: $light-blue;
}

.btn-purple {
    background-color: $purple;
    opacity: 0.9;
    color: $white;

    &:hover {
        background-color: $purple;
        color: $white;
        opacity: 1;

    }

    &:disabled {
        background-color: $purple;
        opacity: 0.65;
        color: $white;
    }
}

.breadcrumb {

    .breadcrumb-item + .breadcrumb-item::before {
        font-family: 'Font Awesome 6 Free';
        content: "\f105";
        font-weight: 900;
    }
}


.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
    outline: none !important;
}

.thread-content {
    p {
        margin-bottom: 0 !important;
    }
}

#loading_screen{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.79);
    z-index : 1051;
}
#loading_screen img{
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
}

.bg-purple{
    background-color: $purple;
}

.hoverable:hover {
    background-color: #f1f1f1;
}

.hand{
    cursor: pointer !important;
}

.thread-content {
    h1,h2,h3,h4,h5,h6,a{
        color: $white !important;
    }
}

//temporarily disabled

//.right-content{
//    h1,h2,h3,h4,h5,h6,a{
//        color: $dark !important;
//    }
//}
